import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'react-responsive'

import { PageMetaData } from '@/components/atomic/PageMetaData'
import { ButtonHierarchy } from '@/components/styleguide/Button'
import { Header } from '@/components/styleguide/Header'
import { RecentlyViewed } from '@/components/styleguide/Homepage/Sections/RecentlyViewed'
import { DEALS_UNDER_300, TopDeals } from '@/components/styleguide/Homepage/Sections/TopDeals'
import { InternalLink } from '@/components/styleguide/Link'
import {
  HeroBackground,
  HeroBackgroundInContainer,
  HeroBackgroundWithoutDashboard,
} from '@/components/views/HomePage/HeroBackground'
import { Search } from '@/components/views/HomePage/Search'
import { SearchForm } from '@/components/views/SearchForm'
import { constants, FILTER_VEHICLE_TYPES_CARS } from '@/lib/constants'
import { useAdminMode } from '@/lib/hooks/useAdminMode'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { useModalData } from '@/lib/hooks/useModalData'
import { ROUTES } from '@/lib/routes'
import { getPageContentListService } from '@/lib/services/pageService'
import { submitSearchService } from '@/lib/services/searchService'
import { getBestDealsByBodyStyleService } from '@/lib/services/vehicleService'
import { storageAvailable } from '@/lib/utilities/system'
import { INITIAL_STATE } from '@/store/reducers/search'
import { Size, Theme } from '@/types/system'

const CollectionsCarousel = dynamic(
  () => import('@/components/styleguide/Homepage/Sections/Collections'),
)
const Brands = dynamic(() => import('@/components/styleguide/Homepage/Sections/Brands'))
const Grid = dynamic(() => import('@/components/views/HomePage/Grid'))
const Cards = dynamic(() => import('@/components/views/HomePage/Cards'))
const LocoScore = dynamic(() => import('@/components/views/HomePage/ValueScore'))
const LocoAi = dynamic(() => import('@/components/styleguide/Homepage/Sections/LocoAi'))
const HowDoesItWork = dynamic(() => import('@/components/views/HomePage/HowDoesItWork'))
const Faqs = dynamic(() => import('@/components/views/HomePage/Faqs'))
const ReferAFriend = dynamic(() => import('@/components/views/HomePage/ReferAFriend'))
const Testimonials = dynamic(() => import('@/components/views/HomePage/Testimonials'))
const NewsletterFormModal = dynamic(() => import('@/components/modals/NewsletterFormModal'))

const HomePage = ({ heroVehicle, faqList, dealList, secondDealList, dealCount }) => {
  const areThereActiveModals = useModalData()
  const isAdminMode = useAdminMode()
  const { ref, inView } = useInView()
  const [isVans] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [version, setVersion] = useState('control')
  const [experimentGroup] = useExperiment()

  useEffect(() => {
    if (experimentGroup.startsWith('homepage-hero-containerise-variant')) {
      setVersion(experimentGroup.split('homepage-hero-containerise-')[1])
    }
  }, [experimentGroup])

  useEffect(() => {
    if (
      storageAvailable('localStorage') &&
      !areThereActiveModals &&
      inView &&
      !window.localStorage.getItem(constants.localStorageKey.newsletterPopup) &&
      !isAdminMode
    ) {
      NiceModal.show(NewsletterFormModal)
    }
  }, [areThereActiveModals, inView, isAdminMode])

  return (
    <main
      className={`container-fluid container--content-homepage is-cars ${version === 'variant-2' ? 'variant' : ''}`}>
      <PageMetaData
        title={constants.metaData.title}
        description={constants.metaData.description}
        pageUrl={ROUTES.homePage.url}
      />
      <Fade
        cascade
        damping={0.1}
        triggerOnce>
        {version === 'variant-1' ? (
          <HeroBackgroundWithoutDashboard showConfetti>
            <Header
              theme={Theme.dark}
              backgroundColor="transparent"
              hasBorderBottom="mobile"
              width="wrapper"
            />
            <Search
              showBottomDash={false}
              isVans={isVans}
              popularSearchesVisibility="desktop"
              animationPosition="top"
              rearViewMirrorVisibility="desktop"
              showConfetti>
              <InternalLink
                hasBackground
                hasPadding
                hasTransition
                href={ROUTES.carSearchPage.url}
                icon="Search"
                theme={Theme.dark}
                hierarchy={ButtonHierarchy.Filled}
                size={Size.Large}>
                Search deals
              </InternalLink>
            </Search>
          </HeroBackgroundWithoutDashboard>
        ) : version === 'variant-2' ? (
          <>
            <Header
              theme={Theme.light}
              backgroundColor="transparent"
              width="wrapper"
            />
            <HeroBackgroundInContainer showConfetti>
              <Search
                showBottomDash={false}
                showConfetti
                popularSearchesVisibility="desktop"
                animationPosition="top"
                isVans={isVans}>
                <InternalLink
                  hasBackground
                  hasPadding
                  hasTransition
                  href={ROUTES.carSearchPage.url}
                  icon="Search"
                  theme={Theme.dark}
                  hierarchy={ButtonHierarchy.Filled}
                  size={Size.Large}>
                  Search deals
                </InternalLink>
              </Search>
            </HeroBackgroundInContainer>
          </>
        ) : (
          <HeroBackground isVans={isVans}>
            <Header
              theme={Theme.dark}
              backgroundColor="transparent"
              width="wrapper"
            />
            <Search
              isVans={isVans}
              popularSearchesVisibility="all">
              {isMobile ? (
                <InternalLink
                  hasBackground
                  hasPadding
                  hasTransition
                  href={ROUTES.carSearchPage.url}
                  icon="Search"
                  theme={Theme.dark}
                  hierarchy={ButtonHierarchy.Filled}
                  size={Size.Large}>
                  Search deals
                </InternalLink>
              ) : (
                <SearchForm
                  hasRedirectToLandingPage
                  initialVehicleType={FILTER_VEHICLE_TYPES_CARS}
                />
              )}
            </Search>
          </HeroBackground>
        )}
      </Fade>
      <RecentlyViewed isVans={isVans} />
      <Brands isVans={isVans} />
      <TopDeals
        dealList={dealList}
        isVans={isVans}
      />
      <div ref={ref}></div>
      <LocoAi />
      <CollectionsCarousel />
      <TopDeals
        dealList={secondDealList}
        isVans={isVans}
        type={DEALS_UNDER_300}
      />
      <Grid
        isVans={isVans}
        dealCount={dealCount}
      />
      <LocoScore
        deal={heroVehicle}
        isVans={isVans}
      />
      <Cards
        isVans={isVans}
        dealCount={dealCount}
      />
      <Testimonials />
      <HowDoesItWork isVans={isVans} />
      <Faqs faqList={faqList} />
      <ReferAFriend isVans={isVans} />
    </main>
  )
}

export default HomePage

export const getServerSideProps = async () => {
  let dealList
  let heroVehicle
  let faqList
  let secondDealList
  let dealCount

  try {
    try {
      const response = await getBestDealsByBodyStyleService({
        vehicleType: FILTER_VEHICLE_TYPES_CARS,
      })
      heroVehicle = response['In Stock SUV (Medium)']
    } catch (err) {
      heroVehicle = null
    }

    try {
      const response = await submitSearchService(INITIAL_STATE.searchParameters)

      dealList = response.results
    } catch (err) {
      dealList = null
    }

    try {
      const response = await submitSearchService({
        ...INITIAL_STATE.searchParameters,
        monthlyPaymentMax: 250,
      })

      secondDealList = response.results
    } catch (err) {
      secondDealList = null
    }

    try {
      const response = await submitSearchService({
        ...INITIAL_STATE.searchParameters,
        monthlyPaymentMax: '',
        mileages: [],
        leaseTypes: [1, 2],
        vehicleTypes: [1, 2],
      })

      dealCount = response.pricesTotal
    } catch (err) {
      dealCount = 0
    }

    try {
      faqList = await getPageContentListService({
        type: 'FAQ_HOMEPAGE',
        page: '1',
      })
    } catch (err) {
      faqList = null
    }

    return {
      props: {
        dealList,
        heroVehicle,
        faqList,
        secondDealList,
        dealCount,
      },
    }
  } catch (err) {
    return {
      props: {
        heroVehicle: null,
        faqList: [],
      },
    }
  }
}
